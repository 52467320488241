// App.tsx
import React from 'react';
import { Outlet } from 'react-router-dom';
import './App.css';
import Nav from './Components/Nav';
import Footer from './Components/Footer';
import CookieConsent from './Components/CookieConsent/CookieConsent';

const App: React.FC = () => (
  <>
    <Nav /> {/* Renderiza o componente de navegação */}
    <Outlet /> {/* Renderiza o conteúdo principal da rota */}
    <Footer /> {/* Renderiza o rodapé da aplicação */}
    <CookieConsent /> {/* Renderiza o componente de consentimento de cookies */}
  </>
);

export default App; // Exporta o componente App por padrão
