import styled from 'styled-components';
import img1 from './assets/BACKGROUD.jpg';
import ScrollReveal from 'scrollreveal';
import { useEffect } from 'react';

interface PanelFlashyProps {  // Criando a interface do componente PanelFlashy
    headline2: string;
    title1: string;
    title2: string;
    text: string;
    buttonText1: string;
    buttonText2: string;
}

// Criando o componente PanelFlashy com os parâmetros headline2, title1, title2, text, buttonText1 e buttonText2
const PanelFlashy: React.FC<PanelFlashyProps> = ({
    headline2,
    title1,
    title2,
    text,
    buttonText1,
    buttonText2
}) => {
    useEffect(() => {// Definindo o comportamento do componente ao ser renderizado
        const reveal = ScrollReveal({
            origin: 'bottom',
            distance: '30px',
            duration: 1000,
            reset: false ,
        });
        reveal.reveal('.PanelFlashy', { delay: 500 });
    }, []);

    // Estrutura do componente PanelFlashy
    return (
        <Container className="PanelFlashy">
            <Box>
                <div className='HeadLine'>
                    <h2 className='SupHead'>______</h2>
                    <h1 className='headline2'>{headline2}</h1>
                </div>
                <div className='Line'>
                    <h2 className='Title'>{title1}</h2>
                    <h2 className='SBTitle'>{title2}</h2>
                </div>
                <p>{text}</p>
                <div className='Buttons'>
                    <button className='Button1'>{buttonText1}</button>
                    <button className='Button2'>{buttonText2}</button>
                </div>
            </Box>
        </Container>
    )
}

export default PanelFlashy;

// Estilização do componente usando styled-components

const Container = styled.div`

    @media (min-width: 768px) {
        padding-top: 70px
    }

    background-size: 110%;
    background-repeat: no-repeat;
    width: 100%;
    height: 90vh;  

    background-image: url(${img1});

    @media (max-width: 768px) {
        background-image: none;
        background-color: #051923;
    }
`;

const Box = styled.div`
    position : relative;
    max-width: 500px;
    padding:5%;
    margin-left: 8rem;

    @media (max-width: 1400px) {
        max-width: 400px;
        
    }

    @media (max-width: 768px) {
        max-width: 350px;
        margin-left:0;
        padding-top: 8rem;
    }

    .HeadLine {
        display: flex;
        flex-direction: start;
        max-width: 500px;
        height: 80px;

    @media (max-width: 768px) {
        max-width: 350px;
    }
}

    .SupHead {
        font-family: arial;
        font-weight: bold;
        margin-right: 10px;
        margin-top: 15px;
        color: #9CACB8;

        @media (max-width: 768px) {
            
        }
        

    }

    h1 {
        font-family: "Roboto Condensed", sans-serif;
        font-optical-sizing: auto;
        font-weight: 400;
        color: #fffff9;
        font-size: 20px;
        word-break: normal;
        max-width: 20;



        @media (max-width: 1400px) {
            font-size: 16px;
            
        }
        
    }

    .Line {
        .Title {
            font-family: "Sarabun", sans-serif;
            font-weight: 800;
            font-size: 78px;
            font-style: normal;
            margin: 0;
            padding: 0;
            color: #2c7da0;

            @media (max-width: 768px) {
                font-size: 35px;
            }

            @media (max-width: 1400px) {
                font-size: 45px;
            }
            
        }

        .SBTitle {
            font-family: "Sarabun", sans-serif;
            font-weight: 800;
            font-size: 78px;
            font-style: normal;
            margin-top: -1rem;
            margin-bottom: 0;
            padding: 0;
            color: #9CACB8;

            @media (max-width: 768px) {
                font-size: 35px;
                margin-top: 5px;
            }
            @media (max-width: 1400px) {
                font-size: 45px;
            }
        }
    }

    p {
        font-family: "Roboto Condensed", sans-serif;
        font-optical-sizing: auto;
        font-weight: 400;
        color: #fffff9;
        font-size: 18px;
        word-break: normal;
        max-width: 20;
        margin-top: 1rem;

        @media (max-width: 1400px) {
            font-size: 16px;
        }
    }

    .Buttons {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        margin-top: 3rem;
        display : none;


    }

    .Button1,
    .Button2 {
        font-family: "Sarabun", sans-serif;
        font-weight: 500;
        font-size: 18px;
        display: flex;
        align-items: center;
        padding: 18px 22px;
        color: #ffffff;
        text-decoration: none;
        cursor: pointer;
        border: none;

        @media (max-width: 768px) {
            font-size: 16px;
        }
    }
    .Button1 {
        color: #202020; 
    }

    .Button1:hover {
        scale: 1.04;
        transition: 0.5s;
    }

    .Button2:hover {
        scale: 1.04;
        transition: 0.5s;
    }

    .Button1 {
        background-color: #9cacb8;
        margin-right: 10px;
    }

    .Button2 {
        background-color: #003554;
    }
`;

