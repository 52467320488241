import React from 'react';
import styled from 'styled-components';

interface TextBoxProps {
    title1: string;
    text1: string;
    text2: string;
    text3: string;
    text4: string;
}

const TextBox: React.FC<TextBoxProps> = ({ title1, text1, text2, text3, text4 }) => {
    return (
        
        <Box>
            <h2 className='Title'>{title1}</h2>
            <p>{text1}</p>
            <p>{text2}</p>
            <p>{text3}</p>
            <p>{text4}</p>
        </Box>
    );
};

export default TextBox;

const Box = styled.div`
    width:625px;
    padding: 5%;
    margin-left: 8rem;


    @media (max-width: 1400px) {
        max-width: 450px;
        
    }

    @media (max-width: 768px) {
        max-width: 450px;
        margin-left: 0;
    }
    p{
        font-family: "Roboto Condensed", sans-serif;
        font-optical-sizing: auto;
        font-weight: 400;
        color: #000000;
        font-size: 18px;
        word-break: normal;
        margin-top: 1rem;
    }
    h2{
        font-family: "Sarabun", sans-serif;
        font-weight: 800;
        font-size: 54px;
        font-style: normal;
        margin-bottom: 2rem;
        padding: 0;
        color: linear-gradient(90deg, rgba(1,0,17,1) 0%, rgba(0,0,54,1) 23%, rgba(0,85,102,1) 100%);
    }
`;
