import React from 'react';
import styled from 'styled-components';
import IMG1 from'../assets/alvo.png';
import IMG2 from'../assets/pessoas-juntas.png';
import IMG3 from'../assets/analise.png';
import IMG4 from'../assets/marketing.png';

interface ValuesProps {
    title: string;
    text: string;
    Title1: string;
    Title2: string;
    Title3: string;
    Title4: string;
    text1: string;
    text2: string;
    text3: string;
    text4: string;
}    

const Values: React.FC<ValuesProps> = ({  title, text, Title1, Title2, Title3, Title4, text1, text2, text3 , text4 }) => {
    return (
        <Container>
            <div className='header'>
                <h2>{title}</h2>
                <p>{text}</p>

            </div>
            <div>
                <div className='Values'>
                    <div className='card'>
                        <div><img src={IMG1} alt="Icon" /></div>
                        <div className='content'>
                            <h1>{Title1}</h1>
                            <p>{text1}</p>
                        </div>
                    </div>


                    <div className='card'>
                        <div><img src={IMG2} alt="Icon" /></div>
                        <div className='content'>
                            <h1>{Title2}</h1>
                            <p>{text2}</p>
                        </div>
                    </div>

                    <div className='card'>
                        <div><img src={IMG3} alt="Icon" /></div>
                        <div className='content'>
                            <h1>{Title3}</h1>
                            <p>{text3}</p>
                        </div>
                    </div>

                </div>
                <div className='footer'>
                <div><img src={IMG4} alt="Icon" /></div>
                        <div className='content'>
                            <h1>{Title4}</h1>
                            <p>{text4}</p>
                        </div>
                </div>
            </div>
        </Container>
    );
}

export default Values;

const Container = styled.div`
margin-top: 5rem;
margin-bottom: 5rem;
max-width: 1000px;


.header{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 3rem;
    h2{
        font-family: "Sarabun", sans-serif;
        font-weight: 800;
        font-size: 54px;
        font-style: normal;
        padding: 0;
        color: linear-gradient(90deg, rgba(1,0,17,1) 0%, rgba(0,0,54,1) 23%, rgba(0,85,102,1) 100%);

        @media (max-width: 768px) {
            font-size: 36px;
        }
    }
    p{
        font-family: "Roboto Condensed", sans-serif;
        font-optical-sizing: auto;
        font-weight: 400;
        color: #000000;
        font-size: 18px;
        word-break: normal;
        margin-top: 1rem;

        @media (max-width: 768px) {
            font-size: 14px;
            max-width: 350px;
        }   
    }


}

.Values{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2rem;

    @media (max-width: 768px) {
        flex-direction: column;
    }
        .card{
            width: 300px;
            height: 200px;
            display: flex;
            gap: 1rem;
        
    }
}

img{
    width: 54px;
    height: 54px;
}

h1{
    font-size: 24px;
    color: #051923;

}
p{
    font-family: "Roboto Condensed", sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    color: #000000;
    font-size: 14px;
    word-break: normal;
    margin-top: 1rem;
}

.footer{

    @media (max-width: 768px) {
        max-width: 350px;
    }

        width: 1000px;
        height: 200px;
        display: flex;
        gap: 1rem;
    

}
`;