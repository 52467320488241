import styled from "styled-components";


interface PaperProps { // Interface de Props para o componente Paper
    title: string;
    text: string;
    image: string;
}



const Paper: React.FC<PaperProps> = ({ title, text, image }) => { // Criando o componente Paper com os parâmetros title, text e image
    return (
        <Container>
            <Image><img src={image} alt="Icon" /></Image>
            <div>
                <Title>{title}</Title>
                <Text> {text}</Text>
            </div>
        </Container>
    )
}

export default Paper

// Estilização do componente Paper usando styled-components
const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2rem;
    margin: 2rem;
    min-width: 600px;

    @media (max-width: 768px) {
        max-width: 340px;
        min-width: 300px;

`;

const Image  = styled.div`
    display: flex;
    
    img{
        width: 64px;
    }

`;
const Title = styled.h4`
font-family: "Sarabun", sans-serif;
font-weight: 800;
font-size: 18px;
font-style: normal;
margin: 0;
padding: 0;
color: #003554;



@media (max-width: 768px) {
    margin-bottom: 10px;

`;

const Text = styled.p`
color: #000000;
font-family: "Roboto Condensed", sans-serif;
font-optical-sizing: auto;
font-weight: 400;
padding:auto;
@media (max-width: 1400px) {
    width: 350px;
    font-size: 14px;
}   

@media (max-width: 768px) {
    diplay:flex;
    max-width: 280px;
    font-size: 12px;

}
`;