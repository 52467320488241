import React from 'react';
import styled from 'styled-components';

interface CardProps {
    title: string;
    backgroundImage: string;
    summary: string;
    link: string;
}

const Article = styled.article`
  width: 350px;
  height: 400px;
  position: relative;
  border-radius: 16px;
  box-shadow: none;
  background: #fff;
  transform-origin: center;
  transition: all 0.4s ease-in-out;
  overflow: hidden;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
  
  &:hover, &:focus {
    --img-scale: 1.1;
    --title-color: #051923;
    --link-icon-translate: 0;
    --link-icon-opacity: 1;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 20px 50px 0px;
  }
`;

const ArticleWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Figure = styled.figure`
  margin: 0;
  padding: 0;
  aspect-ratio: 16 / 9;
  overflow: hidden;
  height: 200px; /* Ajuste a altura conforme necessário */
  width: 100%;
`;

const Img = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  transform-origin: center;
  transform: scale(var(--img-scale, 1.001));
  transition: transform 0.4s ease-in-out;
`;

const ArticleBody = styled.div`
  padding: 24px;
`;

const ArticleTitle = styled.h2`
  margin: 0 0 18px 0;
  font-family: "Roboto Condensed", sans-serif;
  font-size: 1.9rem;
  letter-spacing: 0.06em;
  color: var(--title-color, black);
  transition: color 0.3s ease-out;
`;

const ArticleSummary = styled.p`
  margin-top: 5px;
  font-size: 0.9em;
  font-family: "Roboto", sans-serif;
`;

const ReadMoreLink = styled.a`
  display: inline-flex;
  align-items: center;
  text-decoration: none;
  color: #051923;

  &:focus {
    outline: 1px dotted #051923;
  }

  .icon {
    min-width: 24px;
    width: 24px;
    height: 24px;
    margin-left: 5px;
    transform: translateX(var(--link-icon-translate, -20px));
    opacity: var(--link-icon-opacity, 0);
    transition: all 0.3s;
  }
`;

const Card: React.FC<CardProps> = ({ title, backgroundImage, summary, link }) => {
    return (
        <Article>
            <ArticleWrapper>
                <Figure>
                    <Img src={backgroundImage} alt="" />
                </Figure>
                <ArticleBody>
                    <ArticleTitle>{title}</ArticleTitle>
                    <ArticleSummary>{summary}</ArticleSummary>
                    <ReadMoreLink href={link} target="_blank" rel="noopener noreferrer">
                        Leia Mais <span className="sr-only"></span>
                        <svg xmlns="http://www.w3.org/2000/svg" className="icon" viewBox="0 0 20 20" fill="currentColor">
                            <path fillRule="evenodd" d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z" clipRule="evenodd" />
                        </svg>
                    </ReadMoreLink>
                </ArticleBody>
            </ArticleWrapper>
        </Article>
    );
};

export default Card;
