import React, { useEffect } from 'react';
import styled from 'styled-components';
import ScrollReveal from 'scrollreveal';
import img from "./assets/computador-portatil.png";
import ImgDiag from "./assets/diagnostic.png"
import ImgStrate from "./assets/strategy.png"
import ImgSolu from "./assets/solution.png"
import ImgTrain from "./assets/web-analytics.png"
import ImgMonit from "./assets/analysis.png"
import ImgSup from "./assets/web-development.png"
import Paper from './Paper';

interface ServiceProps {
    backgroundImage: string;
    title1: string;
    title2: string;
    text: string;
}

const Service: React.FC<ServiceProps> = ({
    title1,
    title2,
    text,
    backgroundImage,
}) => {

    useEffect(() => {
        const sr = ScrollReveal({
            origin: 'left',
            distance: '50px',
            duration: 1000,
            reset: true,
            delay: 200
        });

        sr.reveal('.reveal', { interval: 200 });
    }, []);

    return (
        <Container style={{ backgroundImage: `url(${backgroundImage})` }}>
            <Box>
                <div className='Line'>
                    <h2 className='Title'>{title1}</h2>
                    <h2 className='SBTitle'>{title2}</h2>
                </div>
                <p>{text}</p>
            </Box>
            <section>
                <div className="reveal">
                    <Paper
                        title='Diagnóstico Personalizado para seu Negócio'
                        text='Realizamos uma análise completa dos processos e operações de sua empresa, identificando áreas de melhoria e oportunidades de otimização. Nosso diagnóstico detalhado garante que todas as necessidades do seu negócio sejam atendidas.'
                        image={ImgDiag}
                    />
                </div>
                <div className="reveal">
                    <Paper
                        title='Estratégias Sob Medida'
                        text='Desenvolvemos estratégias personalizadas alinhadas aos seus objetivos empresariais. Trabalhamos em colaboração com sua equipe para implementar abordagens específicas que promovam crescimento sustentável e inovação.'
                        image={ImgStrate}
                    />
                </div>
                <div className="reveal">
                    <Paper
                        title='Soluções Inovadoras'
                        text='Implementamos soluções tecnológicas avançadas para melhorar a eficiência operacional, automatizar processos e centralizar informações. Utilizamos as melhores práticas e ferramentas modernas para transformar suas operações.'
                        image={ImgSolu}
                    />
                </div>
                <div className="reveal">
                    <Paper
                        title='Treinamento e Capacitação'
                        text='Oferecemos programas de treinamento personalizados para capacitar sua equipe no uso eficaz de novas tecnologias e sistemas, garantindo o máximo benefício das nossas soluções.'
                        image={ImgTrain}
                    />
                </div>
                <div className="reveal">
                    <Paper
                        title='Monitoramento Contínuo'
                        text='Realizamos monitoramento contínuo dos resultados, ajustando e refinando nossas estratégias conforme necessário para garantir a evolução constante e o sucesso a longo prazo da sua empresa.'
                        image={ImgMonit}
                    />
                </div>
                <div className="reveal">
                    <Paper
                        title='Suporte Proativo'
                        text='Oferecemos suporte contínuo e manutenção proativa para garantir que todas as soluções implementadas funcionem sem problemas, minimizando o tempo de inatividade e mantendo suas operações eficientes'
                        image={ImgSup}
                    />
                </div>
            </section>
        </Container>
    )
}
export default Service


const Container = styled.div`
    background-size: 100%;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    height: auto;  
    margin-bottom: 5rem;

    @media (max-width: 768px) {
        max-width: 350px;

    }

    .Line {
        .Title {
            font-family: "Sarabun", sans-serif;
            font-weight: 800;
            font-size: 64px;
            font-style: normal;
            margin: 0;
            padding: 0;
            color: #003554;

            @media (max-width: 768px) {
                font-size: 45px;
            }
            
        }

        .SBTitle {
            font-family: "Sarabun", sans-serif;
            font-weight: 800;
            font-size: 64px;
            font-style: normal;
            margin-top: -1rem;
            margin-bottom: 0;
            padding: 0;
            color: #9CACB8;

            @media (max-width: 768px) {
                font-size: 45px;
            }
        }
    }

    section{
        display: grid;
        grid-template-columns: repeat(2, 1fr); /* Dois elementos por linha */

        @media (max-width: 768px) {
            display: grid;
            grid-template-columns: repeat(1, 1fr); /* Dois elementos por linha */

            max-width: 350px;
                        
        }
    }
`;

const Box = styled.div`
    max-width: 1400px;
    padding: 5%;

    @media (max-width: 768px) {

        
        
    }

    p {
        font-family: "Roboto Condensed", sans-serif;
        font-optical-sizing: auto;
        font-weight: 400;
        color: #000000;
        font-size: 18px;
        word-break: normal;
        max-width: 600px;
        margin-top: 1rem;

        @media (max-width: 768px) {
            font-size: 16px;
            max-width: 400px;

        }
    }
`;
