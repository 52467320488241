import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom"; // Import BrowserRouter, Routes, and Route
import './index.scss'

import App from "./App";
import Home from "./Pages/Home";
import Error from "./Pages/Error404";
import About from "./Pages/About";
import Innovation from "./Pages/Innovation/Index";
import Consultancy from "./Pages/Consultancy/Index";
import Support from "./Pages/Support/Index";
import Blog from "./Pages/Blog/index";

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route element={<App />}>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/innovation" element={<Innovation />} />
          <Route path="/consultancy" element={<Consultancy />} />
          <Route path="/support" element={<Support />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="*" element={<Error />} />
        </Route>
      </Routes>
    </BrowserRouter>
  </React.StrictMode>,
);