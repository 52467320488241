import React, { useEffect } from 'react';
import VideoBox from './VideoBox';
import Style from './Depositions.module.scss';
import Coment from './Coment';
import ScrollReveal from 'scrollreveal';

// Cria o componente Deps == Depositions == Depoimentos que exibe os depoimentos dos clientes e o vídeo
const Deps = () => {

    useEffect(() => {
        const reveal = ScrollReveal({
            origin: 'bottom',
            distance: '100px',
            duration: 1000,
            reset: false,
        });
        reveal.reveal('.Coments', { delay: 800 });
    }, []);

    return (
        <div className={Style.Container}>
            <VideoBox videoUrl="https://youtu.be/oJtyiBKxSNk" />
            <div className={Style.Box}>
                <section className="Coments">
                    <Coment Coment="Excelente serviço! A equipe realmente superou minhas expectativas. Altamente recomendado!" Corporate="Cliente Econsiste" />
                    <Coment Coment="Profissionais altamente competentes! Fiquei impressionado com a rapidez e eficiência do trabalho realizado." Corporate="Cliente Econsiste" />
                    <Coment Coment="Contratei os serviços e não poderia estar mais satisfeito. Resolveram meu problema de forma rápida e eficaz. Muito obrigado!" Corporate="Cliente Econsiste" />
                    <Coment Coment="Impressionante! Demonstraram um profundo conhecimento e resolveram nosso problema com maestria. Recomendo a todos!" Corporate="Cliente Econsiste" />
                </section>
            </div>
        </div>
    );
}

export default Deps;
