import styled from 'styled-components';


interface PanelFlashyProps {  // Criando a interface do componente PanelFlashy

    title1: string;
    text: string;
}

// Criando o componente PanelFlashy com os parâmetros  title1 e text
const PanelFlashy: React.FC<PanelFlashyProps> = ({

    title1,
    text,

}) => {

    // Estrutura do componente PanelFlashy
    return (
        <Container className="PanelFlashy">
            <Box>
                <div className='Line'>
                    <h2 className='Title'>{title1}</h2>
                </div>
                <p>{text}</p>

            </Box>
        </Container>
    )
}

export default PanelFlashy;

// Estilização do componente usando styled-components

const Container = styled.div`

    @media (min-width: 768px) {
        padding-top: 70px
    }
    background: rgb(4,16,22);
    background: radial-gradient(circle, rgba(4,16,22,1) 0%, rgba(4,44,64,1) 100%);

    @media (max-width: 768px) {
        background-image: none;
        background-color: #051923;
    }
`;

const Box = styled.div`
    position : relative;
    max-width: 1000px;
    padding:5%;
    margin-left: 8rem;

    @media (max-width: 768px) {
        max-width: 600px;
        
    }

    @media (max-width: 768px) {
        max-width: 350px;
        margin-left:0;
        padding-top: 8rem;
    }

    p{
        font-family: "Roboto Condensed", sans-serif;
        font-optical-sizing: auto;
        font-weight: 400;
        color: #fffff9;
        font-size: 18px;
        word-break: normal;
    }
        
    }

    .Line {
        .Title {
            font-family: "Sarabun", sans-serif;
            font-weight: 800;
            font-size: 64px;
            font-style: normal;
            margin: 0;
            padding: 0;
            color: #FFFF;

            @media (max-width: 768px) {
                font-size: 35px;
            }

            @media (max-width: 1400px) {
                font-size: 45px;
            }
            
        }





`;

