import Flashy from "./Flashy";
import About from "./About";
import Servic from "./Servic";
import Deps from "./Depositions";
import Syled from './Home.module.scss'
import React, { useEffect } from 'react';
import ScrollReveal from 'scrollreveal';

const Home = () => {

    useEffect(() => {
        const sr = ScrollReveal({
            reset: false,// Não redefinir após revelar
            duration: 2000, // Defina a duração padrão para 1000ms
            distance: '20px', // Define a distância padrão para 20px
            delay: 0, // Sem atraso padrão
            opacity: 0, // Opacidade inicial padrão de 0
            scale: 0.7, // Escala inicial padrão de 1
            easing: 'ease', // Tipo de easing padrão
        });

        sr.reveal('.SessionTwo', {origin: 'bottom',delay: 200, });
        sr.reveal('.SessionThree', {origin: 'bottom',delay: 400, });
        sr.reveal('.SessionFour', {origin: 'bottom',delay: 600, });
    }, []);

    return (
        <div className={Syled.Container}>

            <Flashy />
            <div id="about" className="SessionTwo">
                <About />
            </div>
            <div id="service" className="SessionThree">
                <Servic />
            </div>
            <div id="depositions" className="SessionFour">
                <Deps />
            </div>
        </div>
    )
}

export default Home