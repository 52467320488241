import { CardProps } from '../../../../Types/Card';
import Style from './Card.module.scss'
import { Link } from 'react-router-dom';


// Define as propriedades de imagem, título, descrição e parágrafo do componente
const Card = ({ imageUrl, title, description, paragrafo, link}:CardProps) => {
    const containerStyle = {
        backgroundImage: `url(${imageUrl})`,
        backgroundSize: 'cover',
    };

    //Cria o componente Card com os parâmetros de imagem, título, descrição e parágrafo
    return (
        <div className={Style.Container} style={containerStyle}>
            <div className={Style.Box}>
                <h4 className={Style.Title}>{title}</h4>
                <h2 className={Style.Text}>{description}</h2>
                <p >{paragrafo}</p>
                <a href={link} className={Style.Button}>Saiba Mais</a>
            </div>
        </div>
    );
}

export default Card;
