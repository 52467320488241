import React from 'react'
import styled from 'styled-components'

import CircleRedirect from '../../Components/CircleRedirect'

import Panel from "../../Components/Panel"
import PanelData from "./data/Consul.json"

import DualBlock from '../../Components/DualBlocks'
import DualBlockData from './data/DualBlock.json'
import IMG2 from '../Consultancy/assets/Consultoria.png'

import TextBox from './Comp/TextBox'

import TextBoxData from './data/Text.json'
import TextBoxDataUp from './data/TextUp.json'
const Consultancy = () => {
    return (
        <div>
            <Panel 
                Title={PanelData.Title} 
                Text={PanelData.Text} 
            /> 
            <Space />
            <TextBox {...TextBoxDataUp} />
            <DualBlock 
                headerText={DualBlockData.headerText} 
                mainText={DualBlockData.mainText} 
                mainPara={DualBlockData.mainPara}
                listItems={DualBlockData.listItems} 
                imageUrl={IMG2} 
            />
            <TextBox {...TextBoxData} />
            <CircleRedirect />
        </div>
    )
}

export default Consultancy



const Space = styled.div`
    height: 3rem;

    @media (max-width: 768px) {
        height: 1rem;
}
`