import React from 'react'
import PanelFlashy from './Comp/PanelFlashy'
import panelData from './data/Panel.json'
import cardsData from './data/Card.json'
import styled from 'styled-components';
import Card from './Comp/Card';
import EstoqueIMG from "./assets/Artigo Gestão Estoque.jpg"
import AutomacaoIMG from "./assets/Artigo Altomacao.jpg"



const IMG =[EstoqueIMG,AutomacaoIMG,]

const BlogContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 35px;
    justify-content: center;
    padding: 20px;
`;

const Blog: React.FC = () => {
    return (
        <div>
            <div >
                <PanelFlashy title1={panelData.title} text={panelData.text} />
            </div>
            <BlogContainer>
                {cardsData.map((card, index) => (
                    <Card
                        key={index}
                        title={card.title}
                        backgroundImage={IMG[index]}
                        summary={card.summary}
                        link={card.link}
                    />
                ))}
            </BlogContainer>
        </div>
    )
}

export default Blog;
