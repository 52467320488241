import React from 'react';
import PanelFlashy from './Comp/PanelFlashy';
import TextBox from './Comp/TextBox';
import ImageGrid from './Comp/ImageGrid';
import IMG1 from './assets/About (1).png';
import IMG2 from './assets/About (2).png';
import IMG3 from './assets/About (3).png';
import Styled from "./About.module.scss"
import Values from './Comp/Values';

const images = [IMG1, IMG2, IMG3];

interface PanelData {
    title: string;
    text: string;
    title2: string;
    Content: {
        p1: string;
        p2: string;
        p3: string;
        p4: string;
    };
}

interface ValuesProps {
    title: string;
    text: string;
    Title1: string;
    Title2: string;
    Title3: string;
    Title4: string;
    text1: string;
    text2: string;
    text3: string;
    text4: string;
}

const jsonData: PanelData = require('./data/Panel.json');
const jsonValues: ValuesProps = require('./data/Values.json');

const About: React.FC = () => {

    return (
        <React.Fragment>
            <div className={Styled.Container}>
            <div >
                <PanelFlashy title1={jsonData.title} text={jsonData.text} />
            </div>
            <div className={Styled.Box}>
                <TextBox 
                    title1={jsonData.title2}
                    text1={jsonData.Content.p1}
                    text2={jsonData.Content.p2}
                    text3={jsonData.Content.p3}
                    text4={jsonData.Content.p4}
                    />
                <ImageGrid images={images} />
            </div>
            <div className={Styled.Values}>
            <Values {...jsonValues} />
            </div>
            </div>
        </React.Fragment>
    );
}

export default About;
