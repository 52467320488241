import React from 'react'
import Service from './Service'
import Img from "./assets/Back.png"
import JsonData  from "./data/Deps.json"
import Style from "./Service.module.scss"


const Servic = () => {
    return (
        <div className={Style.Container}>
        <Service {...JsonData[0]} backgroundImage={Img} />

        </div>
    )
}

export default Servic