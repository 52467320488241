import React, { FC } from 'react';
import { BiSolidChevronsRight } from "react-icons/bi";
import Styled from './DualBlock.module.scss';
import { DualBlockProps } from '../../Types/DualBlocks';

const DualBlock: FC<DualBlockProps> = ({ headerText, mainText, listItems, imageUrl ,mainPara }) => {
    return (
        <div className={Styled.Container}>
            <div className={Styled.Box}>
                    <div className={Styled.Header}>
                        <p>{headerText}</p>
                        <h2>{mainText}</h2>
                        <h5>{mainPara}</h5>
                    <div className={Styled.Content}>
                    <ul>
                        {listItems.map((item, index) => (
                            <li key={index}>
                                <BiSolidChevronsRight   className={Styled.icon}/> {item}
                            </li>
                        ))}
                    </ul>
                    </div>
                </div>
                <div className={Styled.image}>
                    <img src={imageUrl} alt="" />
                </div>
            </div>
        </div>
    );
};

export default DualBlock;
