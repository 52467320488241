import React from 'react'
import styled from 'styled-components'

interface CommentProps { // Define a interface para os comentários
    Coment: string
    Corporate: string
}


const Coment : React.FC<CommentProps> = ( {Coment, Corporate}) => { // Define o componente passando os parâmetros
    return (
        <Section>
            <div>
            <h6>{Corporate}</h6>
            <p>"{Coment}"</p>
            </div>
        </Section>
    )
}

export default Coment


//Estilização do componente usando styled-components
const Section = styled.section` 

    div{
    display: flex;
    flex-direction: column;
    width:250px;
    height: 180px;
    background-color: #EEEEEE;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 10px;
    padding-bottom: 20px;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    transition: transform 0.5s;

    

    &:hover {
        transform: scale(1.03); 
    }

    h6{
        font-family: "Sarabun", sans-serif;
        font-size: 18px;
        margin-bottom: 1rem;
        margin-top: 1.2rem;
    }

    p{
        font-size: 16px;
        margin-top: 0;

    }

}
`;