// CookieConsent.tsx
import React, { useState, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import './CookieConsent.scss';

const CookieConsent: React.FC = () => {
    const [cookies, setCookie] = useCookies(['userConsent']);
    const [visible, setVisible] = useState<boolean>(true);

    useEffect(() => {
        if (cookies.userConsent || cookies.userConsent === false) {
            setVisible(false);
        }
    }, [cookies]);

    const handleAcceptCookies = () => {
        setCookie('userConsent', true, { path: '/' });
        setVisible(false);
    };

    const handleRejectCookies = () => {
        setCookie('userConsent', false, { path: '/' });
        setVisible(false);
    };

    if (!visible) return null;

    return (
        <div className="cookie-consent">
            <div className="cookie-content">
                <p>Este site usa cookies para melhorar sua experiência e personalizar conteúdo. Ao continuar navegando, você concorda com o uso de cookies.</p>
                <div className="cookie-consent-buttons">
                    <button onClick={handleAcceptCookies}>Aceitar</button>
                    <button onClick={handleRejectCookies} className="reject">Rejeitar</button>
                </div>
            </div>
        </div>
    );
};

export default CookieConsent;
