import React from 'react';
import { IoPeopleOutline } from 'react-icons/io5';
import { PiRocket } from 'react-icons/pi';
import { MdOutlineSupportAgent } from 'react-icons/md';
import Styled from './CircleRedirect.module.scss';

const CircleRedirect = () => {
    return (
        <div className={Styled.Container}>
            <div className={Styled.Box}>
                <a href="/innovation"  rel="noreferrer">
                    <div className={Styled.Circle}>
                        <div className={Styled.ProgressBar}>
                            <div className={Styled.Progress}></div>
                        </div>
                        <IoPeopleOutline className={Styled.Icon} />
                        <h2>Inovação</h2>
                        <p>
                            Inove e se destaque no mercado com as soluções tecnológicas avançadas da Econsiste.
                        </p>
                    </div>
                </a>
                <a href="/consultancy"  rel="noreferrer">
                    <div className={Styled.Circle}>
                        <div className={Styled.ProgressBar}>
                            <div className={Styled.Progress}></div>
                        </div>
                        <PiRocket className={Styled.Icon} />
                        <h2>Consultoria</h2>
                        <p>
                            Transforme desafios em oportunidades com a consultoria personalizada da Econsiste.
                        </p>
                    </div>
                </a>
                <a href="/support"  rel="noreferrer">
                    <div className={Styled.Circle}>
                        <div className={Styled.ProgressBar}>
                            <div className={Styled.Progress}></div>
                        </div>
                        <MdOutlineSupportAgent className={Styled.Icon} />
                        <h2>Suporte</h2>
                        <p>
                            Confie na Econsiste para manter seus sistemas funcionando sem interrupções
                        </p>
                    </div>
                </a>
            </div>
        </div>
    );
};

export default CircleRedirect;
