import React from 'react';
import styled from 'styled-components';

interface VideoBoxProps { 
    videoUrl: string;
}

const VideoBox: React.FC<VideoBoxProps> = ({ videoUrl }) => {
    // Construa a URL do iframe do YouTube a partir do videoUrl
    const embedUrl = videoUrl.replace('youtu.be', 'www.youtube.com/embed').replace('watch?v=', 'embed/');

    return (
        <VideoWrapper>
            <div>
                <Title>Depoimentos
                    <p>Bem-vindo à nossa seção de Depoimentos</p>
                </Title>
                <p>Explore depoimentos em vídeo que destacam como nossa consultoria em Protheus TOTVS impulsiona o sucesso dos nossos clientes.
                    Veja como transformamos desafios em oportunidades e inspire-se para alcançar seus próprios objetivos de negócios conosco.</p>
                <Iframe className="video" src={embedUrl} allowFullScreen  />
            </div>
        </VideoWrapper>
    );
};

export default VideoBox;

// Estilização do componente usando styled-components
const VideoWrapper = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    max-width: 1400px;
    padding: 5%;

    @media (max-width: 768px) {
        max-width: 350px;
    }

    div {
        display: flex;
        flex-direction: column;
        iframe {
            align-self: center;
        }
        @media (max-width: 768px) {
            align-self: center;
        }
    }
    p {
        font-family: "Roboto Condensed", sans-serif;
        font-optical-sizing: auto;
        font-weight: 400;
        color: #000000;
        font-size: 18px;
        word-break: normal;
        max-width: 1400px;
        margin-top: 1rem;
    }
`;

const Iframe = styled.iframe`
    border-radius: 10px; 
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
    width: 60%;
    height: 425px; 
    margin-top: 2rem;
    margin-bottom: 2rem;

    @media (max-width: 768px) {
        max-width: 350px;
        width: 100%;
        height: 190px;
    }
`;

const Title = styled.h1`
    color: #003554;

    p {
        font-size: 24px;
    }

    @media (max-width: 768px) {
        
    }
`;
