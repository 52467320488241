import React from 'react'

import CircleRedirect from '../../Components/CircleRedirect'

import Panel from "../../Components/Panel"
import PanelData from "./data/Inovation.json"

import DualBlock from '../../Components/DualBlocks'
import DualBlockData from './data/DualBlock.json'
import IMG from "./assets/Suporte.png"

import TextBox from './Comp/TextBox'
import TextBoxData from './data/Text.json'

const Support = () => {
    return (
        <div>
            <Panel 
                Title={PanelData.Title} 
                Text={PanelData.Text} 
            /> 
            <DualBlock 
                headerText={DualBlockData.headerText} 
                mainText={DualBlockData.mainText} 
                mainPara={DualBlockData.mainPara}
                listItems={DualBlockData.listItems} 
                imageUrl={IMG} 
            />
            <TextBox {...TextBoxData} />
            <CircleRedirect />
        </div>
    )
}

export default Support