import Style from './About.module.scss';
import Card from './Card';
import data from './data/Card.json'; 

import Img1 from './assets/Sup.png';
import Img2 from './assets/Consu.png';
import Img3 from './assets/Inovatio.png';

const IMG =[Img3,Img2,Img1]


//Define o componente passando os parâmetros de imagem, título, descrição e parágrafo
const About = () => {
    return (
        <div className={Style.Container}>
            <div className={Style.Cards}>
                {data.map((item, index) => (
                    <Card
                        key={index}
                        imageUrl={IMG[index]}
                        title={item.title}
                        description={item.description}
                        paragrafo={item.paragrafo}
                        link={item.link}
                    />
                ))}
            </div>
        </div>
    );
};

export default About;
