import React from 'react'
import styled from 'styled-components'


interface TextBoxProps {
    Text: string;}


const TextBox: React.FC<TextBoxProps> = ({ Text }) => {
    return (
        <Container>
            <p>
                {Text}
            </p>
        </Container>
    )
}

export default TextBox



const Container = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top:2rem;

    p {
        font-family: "Roboto Condensed", sans-serif;
        font-optical-sizing: auto;
        font-weight: 400;
        color: #000000;
        font-size: 18px;
        word-break: normal;
        max-width: 20;
        margin-top: 1rem;
        max-width: 1200px;

        @media screen and (max-width: 768px) {
            font-size: 16px;
            max-width: 350px;
        }
    }
}

`