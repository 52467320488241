import { useState, useEffect } from 'react';
import PanelFlashy from './PanelFlashy';
import jsonData from './data/PanelFlashy.json';


// Componente Flashy que exibe um painel de destaque rotativo com dados de um arquivo JSON sendo caregados 
// Dentro do Componente Flashy, o componente PanelFlashy e renderizado
const Flashy = () => {
    const [currentIndex, setCurrentIndex] = useState(0);

    const handleNext = () => { // Função que define o comportamento do componente ao ser renderizado
        setCurrentIndex(prevIndex => (prevIndex + 1) % jsonData.length);
    };

    useEffect(() => { 
        const intervalId = setInterval(handleNext, 6000);
        return () => clearInterval(intervalId);
    }, []);

    return (
        <PanelFlashy
            headline2={jsonData[currentIndex].headline2}
            title1={jsonData[currentIndex].title1}
            title2={jsonData[currentIndex].title2}
            text={jsonData[currentIndex].text}
            buttonText1={jsonData[currentIndex].buttonText1}
            buttonText2={jsonData[currentIndex].buttonText2}
        />
    );
};

export default Flashy;
