import React from 'react';
import Styled from "./Error.module.scss";

const Error = () => {
    return (
        <div className={Styled.Container}>
            <h1 className={Styled.Title}>Erro 404</h1>
            <p className={Styled.Message}>Página não encontrada ou em Desenvolvimento</p>
        </div>
    );
}

export default Error;
