import React, { useEffect } from 'react';
import styled from 'styled-components';
import ScrollReveal from 'scrollreveal';

interface ImageGridProps {
    images: string[];
}

const ImageGrid: React.FC<ImageGridProps> = ({ images }) => {
    useEffect(() => {
        ScrollReveal().reveal('.reveal-left', {
            origin: 'right',
            distance: '50px',
            duration: 1000,
            easing: 'ease-in-out',
            reset: false,
        });
    }, []);

    return (
        <GridContainer>
            <ImageRow>
                <Img src={images[0]} alt="Imagem 1" className="reveal-left" />
                <Empty />
            </ImageRow>
            <ImageRow>
                <Empty />
                <Img src={images[1]} alt="Imagem 2" className="reveal-left" />
            </ImageRow>
            <ImageRow>
                <Img src={images[2]} alt="Imagem 3" className="reveal-left" />
                <Empty />
            </ImageRow>
        </GridContainer>
    );
}

export default ImageGrid;

const GridContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 600px;
    gap: 5px;
    justify-content: center;
    margin-top: 8rem;

    @media (max-width: 1400px) {
        width: 500px;
        justify-content: center;
        margin-top: 4rem;

    }

    @media (max-width: 768px) {
        display: none;
    }   
        

`;

const ImageRow = styled.div`
    display: flex;
    justify-content: space-between;
`;

const Img = styled.img`
    width: 50%;
    height: auto;
    border-radius: 10px;
    margin-bottom: 15px;
    margin-top: 15px;
`;

const Empty = styled.div`
    width: 50%;
    height: auto;
    border-radius: 10px;
`;
