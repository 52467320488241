import React, { useState } from 'react';
import styles from "./Nav.module.scss"; // Alterado para .css
import Logo from '../../assets/LOGO.png'

const Nav = () => {
    const [isActive, setIsActive] = useState(false);

    const toggleNav = () => {
        setIsActive(!isActive);
    };

    // Função para verificar se a URL atual corresponde à página inicial
    const isHomePage = () => {
        return window.location.pathname === '/';
    };

    return (
        <div className={styles.header}> 
            <nav className={`${styles.nav} ${isActive ? styles.active : ''}`}>
            <a href="/"  className={styles.logo}><img src={Logo}alt="" /> <h5>Econsiste</h5></a>
                <button className={styles.hamburger} onClick={toggleNav}></button> 
                <ul className={styles['nav-list']}> 
                    <li><a href="/">HOME</a></li>
                    <li><a href="/about">SOBRE</a></li>
                    {isHomePage() && <li><a href="#depositions">DEPOIMENTOS</a></li>}
                    <li><a href="/blog">BLOG</a></li>
                    <li><a href="https://econsiste.eu.teamwork.com/app/public/forms/MmGdA6mfPnxzePA8RVm4" target='_blank' rel="noopener noreferrer">CHAMADOS</a></li>
                </ul>
            </nav>
        </div>
    );
};

export default Nav;