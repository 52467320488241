import React, { useState, useEffect } from 'react';
import styles from './Panel.module.scss';
import { PanelProps } from '../../Types/Panel';


const Panel: React.FC<PanelProps> = ({ Title, Text }) => {
    const [typedTitle, setTypedTitle] = useState('');

    useEffect(() => {
        let intervalId: NodeJS.Timeout; // Declare intervalId with the type NodeJS.Timeout

        const typeWriter = () => {
            let i = 0;
            intervalId = setInterval(() => {
            if (i < Title.length) {
                setTypedTitle(Title.substring(0, i + 1));
                i++;
            } else {
                clearInterval(intervalId);
            }
            }, 100); // Increase the interval to slow down the typing speed
        };

        typeWriter();
        return () => clearInterval(intervalId);
    }, [Title]);

    return (
        <div className={styles.container}>
            <div className={styles.box}>
                <div className={styles.line}>
                    <h2 className={styles.title}>{typedTitle}</h2>
                </div>
                <p>{Text}</p>
            </div>
        </div>
    );
};

export default Panel;